/** @jsxImportSource @emotion/react */
import * as React from 'react'
import {
	Container,
	TitleSection,
	TitleContainer,
	TitleHeader,
	TitleImageContainer,
	GalleryImageSmall,
	ServiceContainer,
	InfoContentContainer,
	InfoContent,
	FlexRow,
	Column,
	ClientSection,
	ServiceContainerElement,
	ColorStyledAnchor,
	GalleryImageLarge,
} from 'utils/lib'
import * as mq from 'utils/media-queries'
import * as color from 'utils/colors'
import dashboardBanner from 'assets/dashboard-banner.jpg'
import akcStarPuppyLink from 'assets/akc-star-puppy-link.jpg'
import daycareLink from 'assets/daycare-link.jpg'
import boardingLink from 'assets/boarding-link.jpg'
import groomingLink from 'assets/grooming-link.jpg'
import trainingLink from 'assets/training-link.jpg'
import playingVector from 'assets/playing-vector.jpg'

// Temporary pictures. These will likely be updated and moved into new Party Room page
import partyRoomBanner from 'assets/party-room-banner.jpg'
import partyRoom1 from 'assets/party-room-1.jpg'
import partyRoom2 from 'assets/party-room-2.jpg'

import photo3 from 'assets/photo-3.jpg'
import photo2 from 'assets/photo-2.jpg'
import photo4 from 'assets/photo-4.jpg'
import photo5 from 'assets/photo-5.jpg'
import photo6 from 'assets/photo-6.jpg'
import photo7 from 'assets/photo-7.jpg'

function DashboardTitle() {
	return (
		<TitleSection>
			<TitleContainer>
				<TitleHeader
					data-aos="fade-in"
					data-aos-delay="300"
					css={{
						[mq.extraSmall]: {
							fontSize: '3rem',
						},
						[mq.small]: {
							fontSize: '2.25rem',
						},
						[mq.medium]: {
							fontSize: '2.5rem',
						},
						[mq.large]: {
							fontSize: '3rem',
						},
					}}
				>
					<span css={{fontSize: '3.2rem'}}>24</span> Hour Staffed Daycare,
					Boarding, Training and Grooming in LA Habra, CA
				</TitleHeader>
			</TitleContainer>
			<TitleImageContainer src={dashboardBanner} alt="happy-dog" left="-105%" />
		</TitleSection>
	)
}

function ServicesRouter() {
	return (
		<div
			css={{
				paddingTop: '3rem',
				[mq.medium]: {
					paddingTop: '6rem',
					paddingBottom: '3rem',
				},
				[mq.large]: {
					paddingTop: '6rem',
					paddingBottom: '3rem',
				},
			}}
		>
			<ServiceContainer>
				<ServiceContainerElement
					src={akcStarPuppyLink}
					alt="starPuppy"
					to="/training"
					service="AKC S.T.A.R. Puppy"
				/>
				<ServiceContainerElement
					src={daycareLink}
					alt="daycare"
					to="/daycare"
					service="DAYCARE"
				/>
				<ServiceContainerElement
					src={boardingLink}
					alt="boarding"
					to="/boarding"
					service="BOARDING"
				/>
				<ServiceContainerElement
					src={trainingLink}
					alt="training"
					to="/training"
					service="TRAINING"
				/>
				<ServiceContainerElement
					src={groomingLink}
					alt="grooming"
					to="/grooming"
					service="GROOMING"
				/>
			</ServiceContainer>
		</div>
	)
}

function Gallery() {
	return (
		<div>
			<InfoContentContainer>
				<Column>
					<div
						css={{
							borderTop: `1px solid ${color.banner}`,
							paddingBottom: '4rem',
						}}
					/>
				</Column>
				{/* Temporary section to be removed once it's been added as one of the tabs */}
				<Column>
					<div
						css={{
							display: 'flex',
							flex: '40%',
							marginTop: '1rem',
						}}
					>
						<img
							css={{
								width: '100%',
								height: '100%',
								minWidth: '180px',
							}}
							src={partyRoomBanner}
							alt="coming soon party room"
						/>
					</div>
					<InfoContent
						data-aos="fade-in"
						css={{
							fontSize: '1.15rem',
							textAlign: 'center',
							fontFamily: 'Vollkorn, serif',
							margin: '0 auto',
							[mq.small]: {
								minWidth: '320px',
								fontSize: '1.1rem',
							},
						}}
					>
						We will be accepting reservations to reserve our spacious and
						private 24x50 room for events such as birthday parties or other
						celebrations. FRIENDLY and SOCIAL dogs are welcome to attend events
						in our party room with guests. Call us at (562)245-6537 or stop by
						the facility for more information.
					</InfoContent>
				</Column>

				<FlexRow
					css={{
						[mq.medium]: {
							marginTop: '0',
							marginBottom: '0',
						},
						[mq.large]: {
							marginTop: '0',
							marginBottom: '0',
						},
					}}
				>
					<GalleryImageSmall
						data-aos="fade-in"
						src={partyRoom1}
						alt="party room 1"
						css={{
							[mq.medium]: {
								marginRight: '1rem',
							},
							[mq.large]: {
								marginRight: '1rem',
							},
						}}
					/>
					<GalleryImageSmall
						data-aos="fade-in"
						src={partyRoom2}
						alt="party room 2"
						css={{
							[mq.medium]: {
								marginLeft: '1rem',
							},
							[mq.large]: {
								marginLeft: '1rem',
							},
						}}
					/>
				</FlexRow>
				{/* /Temporary section above */}
				<Column>
					<InfoContent
						data-aos="fade-in"
						css={{
							fontSize: '1.15rem',
							textAlign: 'center',
							maxWidth: '500px',
							fontFamily: 'Vollkorn, serif',
							margin: '0 auto',
							[mq.small]: {
								minWidth: '320px',
								fontSize: '1.1rem',
							},
						}}
					>
						Contact us today to find out how we can help provide the care your
						four-legged family members deserve.
					</InfoContent>
					<div
						css={{
							marginTop: '1.5rem',
							textAlign: 'center',
							[mq.extraSmall]: {
								display: 'none',
							},
							[mq.small]: {
								display: 'none',
							},
						}}
					>
						<ColorStyledAnchor
							href={`${process.env.REACT_APP_FACILITY_LIVE_FEED}`}
						>
							Facility Live Feed
						</ColorStyledAnchor>
					</div>
				</Column>
			</InfoContentContainer>

			<InfoContentContainer css={{textAlign: 'center'}}>
				<img
					css={{
						margin: '0 auto',
						maxWidth: '500px',
						[mq.extraSmall]: {
							maxWidth: '300px',
						},
						[mq.small]: {
							maxWidth: '300px',
						},
						[mq.medium]: {
							maxWidth: '400px',
						},
					}}
					src={playingVector}
					alt="playful-dog"
				/>
			</InfoContentContainer>

			<InfoContentContainer
				css={{
					maxWidth: '1280px',
				}}
			>
				<FlexRow
					css={{
						[mq.medium]: {
							marginTop: '0',
							marginBottom: '0',
						},
						[mq.large]: {
							marginTop: '0',
							marginBottom: '0',
						},
					}}
				>
					<GalleryImageSmall
						data-aos="fade-in"
						src={photo5}
						alt="grooming"
						css={{
							[mq.medium]: {
								marginRight: '1rem',
							},
							[mq.large]: {
								marginRight: '1rem',
							},
						}}
					/>
					<GalleryImageSmall
						data-aos="fade-in"
						src={photo3}
						alt="daycare"
						css={{
							[mq.medium]: {
								marginLeft: '1rem',
							},
							[mq.large]: {
								marginLeft: '1rem',
							},
						}}
					/>
				</FlexRow>
				<FlexRow
					css={{
						[mq.medium]: {
							marginTop: '0',
							marginBottom: '0',
						},
						[mq.large]: {
							marginTop: '0',
							marginBottom: '0',
						},
					}}
				>
					<GalleryImageSmall
						data-aos="fade-in"
						src={photo2}
						alt="boarding1"
						css={{
							[mq.medium]: {
								marginRight: '1rem',
							},
							[mq.large]: {
								marginRight: '1rem',
								marginTop: '1rem',
								marginBottom: '1rem',
							},
						}}
					/>
					<GalleryImageSmall
						data-aos="fade-in"
						src={photo4}
						alt="daycare"
						css={{
							[mq.medium]: {
								marginLeft: '1rem',
							},
							[mq.large]: {
								marginLeft: '1rem',
								marginTop: '1rem',
								marginBottom: '1rem',
							},
						}}
					/>
				</FlexRow>
				<FlexRow
					css={{
						[mq.medium]: {
							marginTop: '0',
							marginBottom: '0',
						},
						[mq.large]: {
							marginTop: '0',
							marginBottom: '0',
						},
					}}
				>
					<GalleryImageSmall
						data-aos="fade-in"
						src={photo6}
						alt="boarding1"
						css={{
							[mq.medium]: {
								marginRight: '1rem',
							},
							[mq.large]: {
								marginRight: '1rem',
								marginTop: '1rem',
								marginBottom: '1rem',
							},
						}}
					/>
					<GalleryImageSmall
						data-aos="fade-in"
						src={photo7}
						alt="daycare"
						css={{
							[mq.medium]: {
								marginLeft: '1rem',
							},
							[mq.large]: {
								marginLeft: '1rem',
								marginTop: '1rem',
								marginBottom: '1rem',
							},
						}}
					/>
				</FlexRow>
			</InfoContentContainer>
		</div>
	)
}

function DashboardScreen() {
	React.useEffect(() => {
		// window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<DashboardTitle />

			<ClientSection css={{height: '250px'}}>
				<div
					data-aos="fade-in"
					data-aos-delay="300"
					css={{
						fontSize: '2rem',
						fontFamily: 'Playfair Display, serif',
						marginBottom: '1rem',
						marginLeft: '2rem',
						marginRight: '2rem',
						[mq.extraSmall]: {
							fontSize: '1.5rem',
						},
						[mq.small]: {
							fontSize: '1.5rem',
						},
						[mq.medium]: {
							fontSize: '2rem',
						},
						[mq.large]: {
							fontSize: '3rem',
						},
					}}
				>
					Pampered Paws Salon & Spa
				</div>
				<div
					data-aos="fade-in"
					data-aos-delay="300"
					css={{
						margin: '0 auto',
						width: '75%',
						fontSize: '1rem',
						[mq.extraSmall]: {
							fontSize: '1.1rem',
						},
						[mq.small]: {
							fontSize: '1.1rem',
						},
						[mq.medium]: {
							fontSize: '1.25rem',
						},
						[mq.large]: {
							fontSize: '1.25rem',
						},
					}}
				>
					You can put your trust in us to make sure your loved ones receive the
					special attention they deserve. It will be their home away from home.
				</div>
			</ClientSection>

			<div
				css={{
					marginTop: '3.5rem',
					textAlign: 'center',
					[mq.medium]: {
						display: 'none',
					},
					[mq.large]: {
						display: 'none',
					},
				}}
			>
				<ColorStyledAnchor href={`${process.env.REACT_APP_FACILITY_LIVE_FEED}`}>
					Facility Live Feed
				</ColorStyledAnchor>
			</div>

			<ServicesRouter />

			<Gallery />
		</Container>
	)
}

export {DashboardScreen}
